export const en = {
  "translation": {
    "hablemos_espanol": "Hablemos Español",
    "cursos": "Cursos",
    "contactame": "Contáctame",
    "inscripcion": "Inscripción",
    "home_hero_title": "Cursos de conversación en español en línea",
    "home_hero_subtitle": "El siguiente curso comienza el 11 de Mayo de 2021",
    "mas_informacion": "Más Información",
    "home_motto": "A través de la conversación, mejora tu expresión oral, tu pronunciación y fluidez",
    "home_intro": "En este curso practicarás tus conocimientos del español hablando con nativos y otros estudiantes entusiasmados con el idioma.",
    "info_del_curso": "Información del curso",
    "info_del_curso_item_1": "Orientado a niveles intermedios y avanzados.",
    "info_del_curso_item_2": "Cursos de 8 sesiones de 90 minutos.",
    "info_del_curso_item_3": "Máximo 3 estudiantes por curso.",
    "info_del_curso_item_4": "Temas de conversación basados en tus intereses.",
    "info_del_curso_item_5": "€60 por curso para estudiantes universitarios.",
    "info_del_curso_item_6": "€90 por curso para el público general.",
    "sobre_mi": "Sobre mí",
    "sobre_mi_texto": "¡Hola! Soy Pamela y enseño español en línea desde 2015 y en la SKB de la Universidad Técnica Berlin desde 2018. Soy Licenciada en Enseñanza del Español como Lengua Extranjera por la Universidad Nacional Autónoma de México. Siendo yo misma estudiante de una lengua extranjera, he enfrentado las mismas dificultades que tú al aprender un nuevo idioma. Por eso es que he decidido desarrollar este curso enfocado exclusivamente en la práctica oral, y reforzando la gramática aprendida en cursos previos.",
    "hablemos": "Hablemos",
    "en_construccion": "Esta página está en construcción!",
    "cursos_hero_title": "Cursos de conversación nivel intermedio y avanzado",
    "intermediate_course_title": "Curso nivel intermedio",
    "intermediate_course_item_1": "Nivel: B1/B2.1",
    "intermediate_course_item_2": "Sesiones: Miércoles de 19:00 a 20:30 hrs CET (European time)",
    "intermediate_course_item_3": "Inicio: 12 de Mayo de 2021",
    "intermediate_course_item_4": "Fin: 30 de Junio de 2021",
    "intermediate_course_item_5": "8 sesiones de 90 minutos",
    "intermediate_course_item_6": "Modalidad: via Zoom",
    "intermediate_course_item_7": "Materiales incluidos",
    "intermediate_course_item_8": "Máximo 3 participantes",
    "advanced_course_title": "Curso nivel avanzado",
    "advanced_course_item_1": "Nivel: B2.2/C1",
    "advanced_course_item_2": "Sesiones: Martes de 19:00 a 20:30 hrs CET (European time)",
    "advanced_course_item_3": "Inicio: 11 de Mayo de 2021",
    "advanced_course_item_4": "Fin: 29 de Junio de 2021",
    "advanced_course_item_5": "8 sesiones de 90 minutos",
    "advanced_course_item_6": "Modalidad: via Zoom",
    "advanced_course_item_7": "Materiales incluidos",
    "advanced_course_item_8": "Máximo 3 participantes",
    "course_info_title": "Descripción del curso",
    "course_info_text": "Este curso está orientado a estudiantes universitarios que se encuentren cursando o hayan cursado satisfactoriamente el nivel B 1.1 para el nivel intermedio, y el nivel B2.1 para el nivel avanzado.  \n\nEste curso está enfocado en reforzar los conocimientos ya aprendidos a través de la conversación.  \n\nLos temas de conversación varian según los intereses de los alumnos y se abordan a través de exposiciones, argumentaciones, debates, narraciones, entre otras.",
    "course_objective_title": "Objetivos del curso",
    "course_objective_text": "* Preparar a los estudiantes interesados en hacer un semestre de intercambio en un país hispanohablante, a través de situaciones prácticas a las que se enfrentarán durante su intercambio o, si el estudiante ha estudiado ya en el extranjero, abordar experiencias obtenidas durante su experiencia  \n* Reforzar la gramática ya aprendida en los cursos anteriores  \n* Que los alumnos mejoren la fluidez a través de la conversación oral",
    "prices": "Precios",
    "students": "Estudiantes",
    "student_price": "€60",
    "student_price_description": "Precio exclusivo para estudiantes con credencial vigente o matriculados en alguna universidad.",
    "general_public": "Público general",
    "general_public_price": "€90",
    "general_public_price_description": "Abierto para adultos mayores a 18 años.",
    "name": "Nombre",
    "lastname": "Apellido(s)",
    "email": "Email",
    "message": "Mensaje",
    "send": "Enviar",
    "contact_us_title": "Contáctame",
    "contact_us_text": "¿Tienes dudas? no dudes en escribirme si necesitas mas información.",
    "contact_form_sent": "Gracias por tu mensaje. Muy pronto estaré en contacto contigo.",
    "contact_form_sent_error": "Ha ocurrido un error. Por favor intentalo más tarde.",
    "inscription": "Inscripción",
    "inscription_form_title": "Para inscribirte, llena por favor el siguiente formulario",
    "inscription_spanish_learning_question": "¿En dónde has aprendido antes español?",
    "inscription_are_you_student_question": "¿Eres estudiante?",
    "inscription_course_question": "Curso de interes",
    "yes": "Sí",
    "no": "No",
    "intermediate_level": "Nivel intermedio",
    "advanced_level": "Nivel avanzado",
    "course_calendar": "Calendario del curso",
  }
}